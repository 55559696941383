// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "w_s6 d_bD";
export var storyRowWrapper = "w_hx d_hx d_bK";
export var storyLeftWrapper = "w_s7 d_bz d_bP";
export var storyWrapperFull = "w_s8 d_cD";
export var storyWrapperFullLeft = "w_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "w_mv d_hy";
export var storyLeftWrapperCenter = "w_s9 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "w_tb d_hF";
export var storyHeader = "w_tc d_hD d_w d_cs";
export var storyHeaderCenter = "w_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "w_hB d_hB d_by d_dw";
export var storyBtnWrapper = "w_td d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "w_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "w_tf d_fg d_Z";
export var imageWrapperFull = "w_tg d_w d_H d_bf d_Z";
export var SubtitleSmall = "w_qd t_qd t_rv t_rH";
export var SubtitleNormal = "w_qf t_qf t_rv t_rJ";
export var SubtitleLarge = "w_qg t_qg t_rv t_rK";
export var textLeft = "w_dv";
export var textCenter = "w_dw";
export var textRight = "w_dx";