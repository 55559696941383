// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "p_jQ d_jQ d_cv";
export var galleryMasonryImage = "p_jP d_jP d_w d_bR d_dB";
export var alignLeft = "p_qq d_fp d_bG d_dv";
export var alignCenter = "p_bP d_fq d_bD d_dw";
export var alignRight = "p_qt d_fr d_bH d_dx";
export var galleryContainer = "p_qT d_dW";
export var galleryContainerFull = "p_qV d_dT";
export var galleryRowWrapper = "p_qW d_cc";
export var galleryGuttersImage = "p_jS d_jS d_K d_cv";
export var galleryNoGuttersImage = "p_jR d_jR d_K d_cD";
export var galleryTextGutters = "p_jM d_jM d_cw";
export var galleryTextNoGutters = "p_jN d_jN d_cw";
export var galleryTextMasonry = "p_qX d_jM d_cw";
export var galleryImageWrapper = "p_qY d_fg d_Z";
export var descText = "p_qZ d_jT d_0 d_9 d_7 d_8 d_n";
export var guttersDesc = "p_q0";