// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_q1 d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_q2 d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_q3 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_q4 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_q5 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_q6 d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_q7 d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_q8 t_q8";
export var heroExceptionNormal = "q_q9 t_q9";
export var heroExceptionLarge = "q_rb t_rb";
export var Title1Small = "q_rc t_rc t_rv t_rw";
export var Title1Normal = "q_rd t_rd t_rv t_rx";
export var Title1Large = "q_rf t_rf t_rv t_ry";
export var BodySmall = "q_rg t_rg t_rv t_rP";
export var BodyNormal = "q_rh t_rh t_rv t_rQ";
export var BodyLarge = "q_rj t_rj t_rv t_rR";